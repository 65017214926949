<template>
  <main id="home-test">
    <!-- Hero Banner -->
    <v-sheet id="hero-banner" class="white--text">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="pb-8" style="max-width: 1280px">
        <v-row justify="center">
          <v-col cols="12" md="8">
             <h2 class="text-h4 mt-0 mb-5">
               Think about your biggest problems,<br>
               Love God Love Each Other is the fix to most of them!
               </h2>
            <h2 class="text-h2 mt-0 mb-1">
            <strong class="strong blue--text">Love God Love Each Other</strong>
            </h2>
            <h2 class="text-h2 mt-0 mb-5">
            Treat Others The Way You Want To Be Treated
            </h2>
            <h3 class="text-h5 strong">If I treat you the way I want to be treated,</h3>
            <h3 class="text-h5 strong">I would treat you well, every time.</h3>
            <h3 class="text-h5 red--text text-decoration-underline strong">Think about that!</h3>
            <v-card class="mx-auto rounded-lg mb-0 pb-0" max-width="600" elevation="4" color="white">
              <v-card-text>
                
                <h3 class="text-h5 strong black--text">I would not:</h3>
                <!-- NEW TICKER -->
                    <h3 class="text-h5 strong black--text">
                      <transition name="fade" mode="out-in">
                        <div class="ticker-wrap" v-html="tickerLines[tickerIndex]" :key="tickerIndex"></div>
                      </transition>
                      <h3 class="text-h5 strong">I don't want you to do any of those to me.</h3>
                    </h3>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!--
    <v-img id="hero-banner" position="top center" :src="require('../assets/img/home/banner-sky.jpg')" light>
      <v-sheet style="height: 100%; margin: 0 -1rem;" color="rgba(0,0,0,0.3)" dark>
        <v-container class="fill-height px-7" fluid>
          <v-row justify="center">
            <v-col cols="12" md="8">
               <h2 class="text-h4 mt-0 mb-5">
                 Think about your biggest problems,<br>
                 <strong class="strong blue--text">M</strong>y  <strong class="strong blue--text">O</strong>ne  
                 <strong class="strong blue--text">R</strong>ule is the fix to most of them!
                 </h2>
              <h2 class="text-h2 mt-0 mb-1">
              <strong class="strong blue--text">Love God Love Each Other</strong>
              </h2>
              <h2 class="text-h2 mt-0 mb-5">
              Treat Others The Way You Want To Be Treated
              </h2>
              <h3 class="text-h5 strong">If I treat you the way I want to be treated,</h3>
              <h3 class="text-h5 strong">I would treat you well, every time.</h3>
              <h3 class="text-h5 red--text text-decoration-underline strong">Think about that!</h3>
              <v-card class="mx-auto rounded-lg mb-0 pb-0" max-width="600" elevation="4" color="white">
                <v-card-text>
                  
                  <h3 class="text-h5 strong black--text">I would not:</h3>
                      <h3 class="text-h5 strong black--text">
                        <transition name="fade" mode="out-in">
                          <div class="ticker-wrap" v-html="tickerLines[tickerIndex]" :key="tickerIndex"></div>
                        </transition>
                        <h3 class="text-h5 strong">I don't want you to do any of those to me.</h3>
                      </h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-img>
    -->

    <!-- Sub Banner -->
    <v-sheet style="margin: 0 -1rem;" color="white" dark>
      <v-container class="px-7" fluid>
          <v-row justify="center">
            <v-col cols="12" md="8">
            <h3 class="text-h5 black--text mt-0 mb-0"><strong class= "blue--text">Think about it this way</strong></h3>
               <h5 class="text-h5 black--text mt-1 mb-2">If I treat you the way I want to be treated,<br> 
                I would want good for you!<br>
                </h5>
              <v-card class="mx-auto rounded-lg" max-width="600" elevation="4" color="white">
                <v-card-text>
                <h5 class="text-h5 black--text mt-0 mb-0">I would:<br></h5>
                  <!-- NEW TICKER 2 -->
                  <h3 class="text-h5 strong black--text">
                        <transition name="fade" mode="out-in">
                          <div class="ticker2-wrap" v-html="ticker2Lines[ticker2Index]" :key="ticker2Index"></div>
                        </transition>
                  </h3>
               <h5 class="text-h5 black--text mt-0 mb-0">This sounds good to me!</h5>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
      </v-container>
    </v-sheet>   
   

    <v-sheet style="margin: 0 -1rem;" color="red">
      <v-container class="px-7" fluid>
       <v-row justify="center">
         <v-col cols="12" md="8">
               <h5 class="text-h5 black--text mt-1 mb-2">
                 <strong class="white--text">What if the answer to our problems is not more laws, we have millions.</strong><br>
                 What if we lived <strong class="white--text">Love God Love Each Other</strong>?<br>
                 Imagine living in a place like that?
               </h5>
              <v-card class="mx-auto rounded-lg" elevation="4" max-width="600" color="white">
                <v-card-text>
                  <h5 class="text-h5 black--text mt-0 mb-0">
                    There would be
                  </h5>
                  <!-- NEW TICKER 3 -->
                  <h3 class="text-h5 strong black--text">
                    <transition name="fade" mode="out-in">
                      <div class="ticker3-wrap" v-html="ticker3Lines[ticker3Index]" :key="ticker3Index"></div>
                    </transition>
                  </h3>
                  <h5 class="text-h5 black--text mt-1 mb-2">
                  <strong>That's where I want to live.</strong>
                  </h5>
                </v-card-text>
              </v-card>
              <br>
               <h5 class="text-h5 black--text font-weight-bold mb-0">
                  That is the Love God Love Each Other mission.<br>
                  </h5>
         </v-col>
      </v-row>
      </v-container>
    </v-sheet>
    <v-sheet style="margin: 0 -1rem;" color="white" dark>
        <v-container class="px-7" fluid>
          <v-row justify="center">
            <v-col cols="12" md="8">
              <div class="text-h5 black--text font-weight-bold mb-2">
                  Without <strong class="blue--text">Love God Love Each Other</strong> and <strong class="red--text">YOUR</strong> help<br>
                  our future is very shaky!
                </div>
                <div class="" v-animate-css="animationInfinite2">
                <v-row class="ma-0" align="center" justify="center">
                  <v-img :src="require('../assets/img/globe-image.png')" :max-height="250" max-width="250">
                  <template v-slot:placeholder>
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Globe image loader."></v-progress-circular>
                  </template>
                  </v-img>
                </v-row>
              </div>
              <br>
              <div class="text-h5 blue--text font-weight-bold mb-2" v-animate-css="animationInfinite">
               Join the Love God Love Each Other Movement!
              </div>
              <div class="text-h5 blue--text font-weight-bold mb-2">
                There are three steps, and anyone can do it!<br>
              </div>
            </v-col>
          </v-row>
        </v-container>
    </v-sheet>
    
    <!-- Pledge Form -->
    <h3 class="text-h5 blue--text font-weight-bold mb-2"><h3>Step 1:</h3> Sign up and live Love God Love Each Other.<br></h3>
    <v-container class="rounded-lg flex-grow-1" style="max-width: 1280px">  
      <v-col id="pledge" class="d-flex" cols="12" md="12">
        <PledgeForm/>
      </v-col>
    </v-container>
    
    <!-- Pledge Map-->
    <v-sheet id="map-view">
      <v-container style="max-witdh: 1280px mt-0 pt-0">
        <v-row justify="center">
          <v-col cols="12 mt-0 pt-0">
            <div class="text-h5 blue--text font-weight-bold">
              Put your name on the map<br>
              See the Love God Love Each Other communities where people are making things better. 
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pa-0 ma-0" style="height: 500px" fluid fill-height>
        <v-row align="stretch" style="height: 100%" no-gutters>
          <v-col class="d-flex" cols="12">
            <PledgeMap/>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
   
     <!-- Live -->
            <v-col class="d-flex" cols="12">
              <v-container class="d-flex flex-column rounded-lg" style="max-width: 1280px" fluid>
                <div>
                  <v-img class="mx-auto rounded-lg" :aspect-ratio="16/9">
                    <iframe src="https://www.youtube.com/embed/eB8obC9wyuM?rel=0" title="Living Love God Love Each Other Video" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                  </v-img>
                </div>
                <v-card-text class="flex-grow-1">
                  <div class="text-h6 black--text mb-4">
                    <strong>Living Love God Love Each Other</strong> means filtering your thoughts and actions to help achieve better outcomes.<br>
                    It’s your commitment to be a better person, every day. You’re in control of how you treat others.<br>
                    With Love God Love Each Other reminders, you will be a positive influence in your community.
                  </div>
                  <v-btn class="mb-0" color="primary" @click="$router.push({ path: 'about', query: { target: '#steps', step: 0 }})">
                    Learn Love God Love Each Othere
                  </v-btn>
                </v-card-text>
              </v-container>
            </v-col>


    <!-- Reminders -->
    <v-sheet id="reminders">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="pb-8" style="max-width: 1280px" fluid>
        <v-row justify="center">
          <v-col cols="12" md="8">
            <h3 class="text-h5 blue--text mt-1 mb-3">
              <strong>
              Reminders to Live Love God Love Each Other
              </strong>
            </h3>
            <div class="text-h6 black--text mb-4">
              Life happens fast, it is easy to forget how you want to live. 
              But with visible, powerful reminders you can make better decisions all day and be the person you want to be. 
            </div>
            <div class="text-h6 black--text mb-5">
              They will also improve your life and help you share <strong>Love God Love Each Other</strong> with others.
            </div>
            <v-card class="rounded-lg mb-6" flat>
              <v-img :aspect-ratio="3.2">
                <video autoplay="autoplay controls" loop="loop" muted="muted" style="width: 100%">
                  <source :src="require('../assets/video/product/wristband.mp4')" type="video/mp4"> Your browser does not support the video tag. 
                </video>
              </v-img>
              <v-card-actions>
                <v-btn class="mx-auto px-8" color="red darken-3" href="https://shop.myonerule.com/" large dark>
                  Shop Reminders
                  <v-icon right dark>
                    mdi-cart
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
    <div class="text-h5 blue--text font-weight-bold mb-2">
    <br>
    <h3>Step 2:</h3> Share Love God Love Each Other.<br>
    </div>
    <!-- Share -->
            <v-col class="d-flex" cols="12">
              <v-container class="d-flex flex-column rounded-lg" style="max-width: 1280px" fluid>
                <div>
                  <v-img class="mx-auto rounded-lg" :aspect-ratio="16/9">
                    <iframe src="https://www.youtube-nocookie.com/embed/D5NMcAmvlHc?rel=0" title="Sharing Love God Love Each Other Video" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                  </v-img>
                </div>
                <v-card-text class="flex-grow-1">
                  <div class="text-h6 black--text mb-4">
                    Once you see what Love God Love Each Other can do in your life, you’ll naturally want to share it with others.<br>
                    Use conversation starters to open dialogues in your family, friend group, and community.<br> 
                    <br>
                    If you share Love God Love Each Other with two people and they each do the same and the sharing continues,<br>
                    the world can change for the better almost immediately.<br>
                    <strong>We can do this together!</strong>
                  </div>
                  <v-btn class="mb-0" color="primary" @click="$router.push({ path: 'about', query: { target: '#steps', step: 1 }})">
                    Learn Love God Love Each Othere
                  </v-btn>
                </v-card-text>
              </v-container>
            </v-col>
    
    <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
    <div class="text-h5 blue--text font-weight-bold mb-2">
    <br>
    <h3>Step 3:</h3> Make Love God Love Each Other The Standard.<br>
    </div>
    <!-- Make -->
            <v-col class="d-flex" cols="12">
              <v-container class="d-flex flex-column rounded-lg" style="max-width: 1280px" fluid>
                <div>
                  <v-img class="mx-auto rounded-lg" :aspect-ratio="16/9">
                    <iframe src="https://www.youtube-nocookie.com/embed/eg1Uj8K9qII?rel=0" title="Making It The Standard Video" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                  </v-img>
                </div>
                <v-card-text class="flex-grow-1">
                  <div class="text-h6 black--text mb-2">
                    Making Love God Love Each Other the standard means living <strong>Love God Love Each Other</strong> in every interaction.<br>
                    Families, organizations, schools, politicians, and businesses can all benefit from adopting Love God Love Each Other.<br>
                    Use reminders to help you and others to make the world better everyday!
                  </div>
                  <v-btn class="mb-0" color="primary" @click="$router.push({ path: 'about', query: { target: '#steps', step: 2 }})">
                    Learn Love God Love Each Othere
                  </v-btn>
                </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#pledge')" block large>
                    Join Us Now!
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              </v-col>
            </v-container>
            </v-col>
    <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>

    <!-- Benefit Cards -->
    <v-sheet id="benefits">
      <v-container class="pb-1" style="max-width: 1280px">
        
        <!-- Benefits -->
        <v-row justify="center">
            <div class="text-h3 font-weight-light my-4">
              Want More Benefits?
            </div>
        </v-row>
        <v-row justify="center">
          
        <!-- Happiness -->
        <v-sheet id="Happiness" color="white">
          <div class="text-h4 blue--text text--darken-3 mb-0">
          Love God Love Each Othere Peace and Happiness
          </div>
          <v-col class="d-flex justify center pb-0 mb-0" cols="12">
            <v-container class="rounded-lg flex-grow-1">
              <v-img :src="require('../assets/img/home/card-happiness.jpg')" :aspect-ratio="16/9">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Better relationships card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text>
                
                <div class="text-h6 black--text mb-0">
                  <strong class="text-h6">Love God Love Each Other</strong> helps achieve better solutions, better cures, better overall results.<br>
                  <strong class="text-h6">Love God Love Each Other</strong> cures Love God Love Each Othere problems than ten million laws, millions of police, thousands of prisons and millions of doctors.
                
                
                <v-row justify="black--text center">
                  <v-col cols="6">
                    <ul class="text-h6 text-center pa-0" style="list-style: none">
                      <li>With Love God Love Each Other you have less:</li>
                      <strong>
                      <li>Regrets</li>
                      <li>Anxiety</li>
                      <li>Stress</li>
                      <li>Fighting</li>
                      <li>Selfishness</li>
                      </strong>
                    </ul>
                  </v-col>
                  <v-col cols="6">
                    <ul class="text-h6 text-center pa-0" style="list-style: none">
                      <li>With Love God Love Each Other you have Love God Love Each Othere:</li>
                      <strong>
                      <li>Peace</li>
                      <li>Respect</li>
                      <li>Friends</li>
                      <li>Kindness</li>
                      <li>Happiness</li>
                      </strong>
                    </ul>
                  </v-col>
                </v-row>
                <strong>Love God Love Each Other</strong> is Love God Love Each Othere effective at reducing stress than exercise, alcohol and drugs, combined!<br>
                <br>
                Love God Love Each Other brings Love God Love Each Othere peace and happpiness, by Treating Others The Way You Want To Be Treated. <br>
                You will make better decisions, have less regret and the people around you will treat you better too!
              </div>
              </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                   <v-btn class="" color="primary" @click="$vuetify.goTo('#pledge')" block large>
                    Join Us Now!
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-container>
          </v-col>
          </v-sheet>

          <!-- Relationships -->
          <v-col class="d-flex justify center pb-0 mb-0" cols="12">
            <v-container class="rounded-lg flex-grow-1 pb-0 mb-0">
              <div class="text-h4 blue--text text--darken-3 mb-0">Better Relationships</div>
               <v-img class="mx-auto" :src="require('../assets/img/home/card-relationships.jpg')" :aspect-ratio="16/9">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Relationships card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text>
                
                <div class="text-h6 black--text mb-1">
                  <strong class="text-h6">Love God Love Each Other</strong> is the best foundation for communication and healing misunderstandings.
                </div>
                <div class="text-body-1 black--text">
                  <ul class="text-h6 black--text text-center" style="list-style: none">
                      <strong>
                      <li>Living Love God Love Each Other produces:</li>
                      <li>Love God Love Each Othere Friends</li>
                      <li>Better work relationships</li>
                      <li>Love God Love Each Othere loving families</li>
                      </strong>
                </ul>
                </div>
                <div class="text-h6 black--text mb-1">
                Love God Love Each Other creates better relationships by being the best foundation for communication.<br> 
                If you live Love God Love Each Other you will communicate to others with diginity and respect, because that is how we all want to be treated!
                </div>
                
              </v-card-text>
            </v-container>
          </v-col>

          <div class="text-h4 blue--text text--darken-3 mb-0">
                  Safer Communities
          </div>
          <!-- Communities -->
          <v-col class="d-flex" cols="12">
            <v-container class="rounded-lg flex-grow-1">
              <v-img class="mx-auto rounded-lg" :src="require('../assets/img/home/card-communities.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Communities card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text>
                
                <div class="text-h6 black--text mb-0 pa-0">
                  <strong class="text-h6">Love God Love Each Other</strong> is the best way to build better communities.
                </div>
                <div class="text-body-1 black--text pa-0">
                <v-row justify="center">
                  <v-col cols="6 pa-0">
                    <ul class="text-h6 text-center" style="list-style: none">
                      <li>With Love God Love Each Other you have less:</li>
                      <li>Lying</li>
                      <li><a href="/racism">Racism</a></li>
                      <li><a href="/bullying">Bullying</a></li>
                      <li>Stealing</li>
                      <li>Murder</li>
                    </ul>
                  </v-col>
                  <v-col cols="6 pa-0">
                    <ul class="text-h6 text-center" style="list-style: none">
                      <li>With Love God Love Each Other you have Love God Love Each Othere:</li>
                      <li>Safety</li>
                      <li>Happiness</li>
                      <li>Respect</li>
                      <li>Kindness</li>
                      <li>Peace</li>
                    </ul>
                  </v-col>
                </v-row>
                </div>
                <div class="text-h6 black--text mb-0 pa-0">
                  Love God Love Each Other creates safer communities by reducing lying, racism, bullying and a boat load of other bad stuff.<br> 
                  This yields a community that is safer, happier, and Love God Love Each Othere respectful.<br>
                  You can't lie, hate, steal or murder and live by Love God Love Each Other.
                </div>
              </v-card-text>
            </v-container>
          </v-col>
          <div class="text-h4 blue--text text--darken-3 mb-0">
                  A Better Life
                </div>
          <!-- Better Life -->
          <v-col class="d-flex" cols="12">
            <v-container class="rounded-lg flex-grow-1">
               <v-img class="mx-auto rounded-lg" :src="require('../assets/img/home/card-better-life.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Better life card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text>
                
                <div class="text-h6 black--text">
                  <strong>Sometimes a better life means Love God Love Each Othere of some things and less of others.</strong><br> 
                  Imagine how the news and your life would change, if we lived by Love God Love Each Other.
                </div>
                <v-row class="black--text" justify="center">
                  <v-col cols="6">
                    <div class="text-h6">
                    There would be less:
                    </div>
                    <ul class="text-h6" style="list-style: none">
                      <strong>
                      <li><a href="/racism">Racism</a></li>
                      <li><a href="/bullying">Bullying</a></li>
                      <li>Murder</li>
                      <li>Lying</li>
                      <li>Stealing</li>
                      <li>Meanness</li>
                      </strong>
                      <v-btn class="" color="red darken-3" @click="reduceDialog = true" large dark>
                      See List
                      </v-btn>
                    </ul>
                  </v-col>
                  <v-col cols="6">
                    <div class="text-h6">
                    There would be Love God Love Each Othere:
                    </div>
                    <ul class="text-h6" style="list-style: none">
                      <strong>
                      <li>Kindness</li>
                      <li>Love</li>
                      <li>Respect</li>
                      <li>Peace</li>
                      <li>Friends</li>
                      <li>Joy</li>
                      </strong>
                      <v-btn class="" color="primary" @click="improveDialog = true" large>
                      See List
                      </v-btn>
                    </ul>
                    
                  </v-col>
                </v-row>
                <div class="text-h6 black--text">
                 Living Love God Love Each Other is the best way to live a better life.
                </div>
              </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#pledge')" block large>
                    Join Us Now!
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-container>
          </v-col>

          <!-- Reduce Modal -->
          <v-dialog v-model="reduceDialog" max-width="350px" scrollable>
            <v-card class="rounded-lg d-flex flex-column flex-grow-1" elevation="4">
              <v-card-title>
                <div class="text-h6 red--text text--darken-3">
                  Love God Love Each Other Can Reduce:
                </div>
              </v-card-title>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text class="black--text" style="height: 300px">
                <v-row justify="center">
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li><a href="/bullying">Bullying</a></li>
                      <li>Cancel Culture</li>
                      <li><a href="/racism">Racism</a></li>
                      <li>Hunger</li>
                      <li>Fighting</li>
                      <li>Cheating</li>
                      <li>Lying</li>
                      <li>Selfishness</li>
                      <li>Rape</li>
                      <li>Murder</li>
                      <li>War</li>
                      <li>Regret</li>
                      <li>Coveting</li>
                      <li>Hate</li>
                      <li>Abuse</li>
                      
                    </ul>
                  </v-col>
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li>Wasted Taxes</li>
                      <li>Hopelessness</li>
                      <li>Community Fears</li>
                      <li>Riots</li>
                      <li>Stealing</li>
                      <li>Adultery</li>
                      <li>Violence</li>
                      <li>School Shootings</li>
                      <li>Slavery</li>
                      <li>Hate Speech</li>
                      <li>National Debt</li>
                      <li>Nastiness</li>
                      <li>Mean People</li>
                      <li>Voter Fraud</li>
                    </ul>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col>
                    <p class="text-body-1 mb-0">
                      <strong>And about a million other things!</strong>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="reduceDialog = false" text>
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Improve -->
          <v-dialog v-model="improveDialog" max-width="350px" scrollable>
            <v-card class="rounded-lg d-flex flex-column flex-grow-1" elevation="4">
              <v-card-title>
                <div class="text-h6 blue--text text--darken-3">
                  Love God Love Each Other Can Improve:
                </div>
              </v-card-title>
              <v-card-text class="black--text" style="height: 300px">
                <v-row justify="center">
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li>Kindness</li>
                      <li>Goodness</li>
                      <li>Helpfulness</li>
                      <li>Health</li>
                      <li>Respect</li>
                      <li>Safety</li>
                      <li>Hope</li>
                      <li>Sleep</li>
                    </ul>
                  </v-col>
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li>Peace</li>
                      <li>Love</li>
                      <li>Joy</li>
                      <li>Relationships</li>
                      <li>Families</li>
                      <li>Communities</li>
                      <li>School</li>
                      <li>Work</li>
                    </ul>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col>
                    <p class="text-body-1 mb-0">
                      <strong>And about a million other things!</strong>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="improveDialog = false" text>
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div class="text-h4 blue--text text--darken-3">
                  With Love God Love Each Other, it's not about left or right
                </div>
          
          <!-- It's not about politics -->
          <v-col class="d-flex" cols="12">
            <v-container class="rounded-lg flex-grow-1">
             <v-img class="mx-auto" :src="require('../assets/img/fighting.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Polotics card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text>
                
                <div class="text-h6 black--text">
                  <strong class= "text-h6">It's not about politics.</strong><br>
                  <strong>It's about being good and doing good. </strong>
                  <strong>It's about treating others, the way you want to be treated!</strong>
                </div>
                <div class="text-h6 black--text">
                  When you live by Love God Love Each Other, you are quick to listen, slow to speak and slow to get angry.<br> 
                  You seek the truth and tell the truth, no lies. You work to understand.<br> 
                  You can’t steal, because no one wants their stuff taken and neither do you.<br>
                  You take responsibility for your actions.<br>
                  You don’t fight each other, you only fight the problems.<br>
                  With Love God Love Each Other politicians serve the citizens.
                </div>
              </v-card-text>
            </v-container>
          </v-col>

          <div class="text-h4 blue--text text--darken-3">
                  Who Can Do It?
                </div>
          <!-- Who Can Do it -->
          <v-col class="d-flex" cols="12">
            <v-container class="rounded-lg flex-grow-1">
              <v-img class="mx-auto" :src="require('../assets/img/home/card-everyone.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Who can do it card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text>
                <div class="text-h6 black--text">
                  <strong>You, and everyone else.</strong><br>
                  Living Love God Love Each Other is low risk, with an extremely high reward.
                </div>
                <div class="text-h6 black--text">
                Everyone can live Love God Love Each Other, there is no reason not to.
                </div>
              </v-card-text>
            </v-container>
          </v-col>
          
          <div class="text-h4 blue--text text--darken-3 mb-3">
                  You are here for a reason! <br>
                  You matter and you care.
                </div>
          <!-- So what do you want to do? -->
          <v-col class="d-flex" cols="12">
            <v-container class="rounded-lg flex-grow-1">
              <v-img class="mx-auto" :src="require('../assets/img/home/card-why-not.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="What do you want to do card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-text>
                <div class="text-h6 black--text">
                  Don't wait for someone else to do something <strong>only you can do.</strong><br>
                  If you see an opportunity to do something good, do it.<br>  
                  If you can stop something bad, stop it.<br>
                  <strong class="blue--text">If you don't, who will?</strong><br>
                  We can solve and cure a world of problems.<br>
                  For some of us that means get off the sidelines and do something.<br>
                  <strong>NOW IS THE TIME!</strong><br>
                  <strong>THIS IS THE PLACE!</strong><br>
                </div>
            </v-card-text>
            <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                   <v-btn class="" color="primary" @click="$vuetify.goTo('#pledge')" block large>
                    Join Us Now!
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Pledge Section -->
    <v-sheet id="pledge-form" color="white">
      <v-container class="py-8" style="max-width: 1280px" fill-height>
        <v-row justify="center">
          <v-col class="d-flex" cols="12" md="12">
            <!-- Video -->
            <v-container class="d-flex flex-column justify-start rounded-lg flex-grow-1" color="white">
              <!-- Video -->
              <div>
                <v-img class="mx-auto rounded-lg" :aspect-ratio="16/9">
                  <iframe src="https://www.youtube-nocookie.com/embed/QEQoHf1rtiw?rel=0" title="Live Love God Love Each Other Video" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                </v-img>
              </div>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Testimonials -->
    <v-sheet id="testimonials" color="grey lighten-3">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container style="max-width: 1280px;">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 blue--text font-weight-bold my-4">
              Testimonials
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="12">
            <v-carousel height="auto" hide-delimiters light>
              <v-carousel-item v-for="(t, i) in testimonials" :key="'testimonial-' + i">
                <v-row class="fill-height" justify="center" align="center">
                  <v-col cols="10">
                    <p class="text-h6 font-italic">{{ t.text }}</p>
                    <h6 class="text-h6">{{ t.name }}</h6>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </main>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

#hero-banner {
  background: rgb(0,75,130);
  background: linear-gradient(180deg, rgba(0,75,130,1) 0%, rgba(136,192,255,1) 100%);
}
</style>

<script>
import PledgeForm from "../components/PledgeForm.vue";
import PledgeMap from "../components/PledgeMap.vue";

export default {
  name: 'Home',
  data: () => ({
    reduceDialog: false,
    improveDialog: false,

    // New ticker config.
    tickerIndex: 0, // Don't touch this.
    tickerInterval: 2500, // Milliseconds | 1000 = 1 second
    tickerLines: [
      `<strong class= "red--text">Lie</strong> to you.`,
      `<strong class= "red--text">Hate</strong> you for your color.`,
      `<strong class= "red--text">Hate</strong> you for your politics.`,
      `<strong class= "red--text">Hate</strong> you for your beliefs.`,
      `<strong class= "red--text">Say</strong> bad, untrue things about you.`,
      `<strong class= "red--text">Silence</strong> you`,
      `<strong class= "red--text">Bully</strong> you`,
      `<strong class= "red--text">Muder</strong> you`,
      `<strong class= "red--text">Steal</strong> from you`,
      `<strong class= "red--text">Cancel</strong> you`,
      `<strong class= "red--text">Harm</strong> your stuff`,
      `<strong class= "red--text">Harm</strong> your kids`,
      `<strong class= "red--text">Abuse</strong> you`,
      `<strong class= "red--text">Tresspass</strong> on your property`,
      `<strong class= "red--text">Take</strong> advantage of you`,
      `<strong class= "red--text">Cheat</strong> you`,
      `<strong class= "red--text">Waste</strong> your money`
    ],

    // New ticker config 2.
    ticker2Index: 0, // Don't touch this.
    ticker2Interval: 2500, // Milliseconds | 1000 = 1 second
    ticker2Lines: [
      `<strong class="blue--text">Help</strong> you if you were in trouble`,
      `<strong class="blue--text">Work</strong> to live in peace with you.`,
      `<strong class="blue--text">Respect</strong> you as a person.`,
      `Be <strong class="blue--text">kind</strong> to you.`
    ],

    // New ticker config 3.
    ticker3Index: 0, // Don't touch this.
    ticker3Interval: 2000, // Milliseconds | 1000 = 1 second
    ticker3Lines: [
      `<strong class= "blue--text">Happier families.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere hope.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere joy.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere love.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere kindness.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere respect.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere safety.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere happiness.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere helpfulness.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere good.</strong>`,
      `<strong class= "blue--text">Love God Love Each Othere peace.</strong>`,
      `<strong class= "blue--text">Better solutions.</strong>`,
      `<strong class= "blue--text">Better health.</strong>`,
      `<strong class= "blue--text">Better sleep.</strong>`,
      `<strong class= "blue--text">Better relationships.</strong>`,
      `<strong class= "blue--text">Better work environment.</strong>`,
      `<strong class= "blue--text">Happier communities.</strong>`,
      `<strong class= "blue--text">Less fighting.</strong>`,
      `<strong class= "blue--text">Less selfishness.</strong>`,
      `<strong class= "blue--text">Less regret.</strong>`,
      `<strong class= "blue--text">Less hopelessness.</strong>`,
      `<strong class= "blue--text">Less war.</strong>`,
      `<strong class= "blue--text">Less coveting.</strong>`,
      `<strong class= "blue--text">Less community fears.</strong>`,
      `<strong class= "blue--text">Less riots.</strong>`,
      `<strong class= "blue--text">Less adultery.</strong>`,
      `<strong class= "blue--text">Less violence.</strong>`,
      `<strong class= "blue--text">Less school shootings.</strong>`,
      `<strong class= "blue--text">Less slavery.</strong>`,
      `<strong class= "blue--text">Less national debt.</strong>`,
      `<strong class= "blue--text">Less nastines.</strong>`,
      `<strong class= "blue--text">Less Mean people.</strong>`,
      `<strong class= "blue--text">Less fraud.</strong>`,
      `<strong class= "blue--text">Less crime.</strong>`,
      `<strong class= "blue--text">Less stress.</strong>`,
      `<strong class= "blue--text">and a million Love God Love Each Othere benefits</strong>`
    ],
    
    //Pledge Animation
    animationInfinite: {
      classes: 'pulse',
      duration: 1000,
      iteration: 'infinite'
    }, 

    //Pledge Animation 2
    animationInfinite2: {
      classes: 'shake',
      duration: 1500,
      iteration: 'infinite'
    }, 

    //Pledge Animation 3
    animationInfinite3: {
      classes: 'tada',
      duration: 6000,
      iteration: 'infinite'
    }, 

    // Testimonial entries.
    testimonials: [
      {
        name: "Bill W.",
        text: 
          `Wearing the Love God Love Each Other reminders has helped me make better decisions. 
          I have found myself getting less angry and by treating others better, they treat me better.`,
      },
      {
        name: "Pat C.",
        text: `Life isn't fair, but I can be.
              Living Love God Love Each Other has improved my relationships and people have noticed.`,
      },
      {
        name: "Frida",
        text: `My one-year old son has a cleft-hand, I am worried he will be bullied. 
              With Love God Love Each Other I don't need to be worried.`
      }
    ]
  }),
  components: {
    PledgeForm,
    PledgeMap
  },

  mounted() {
    // Set up the ticker interval function.
    setInterval(() => {
      this.tickerIndex += 1;
      
      this.tickerIndex %= this.tickerLines.length;
    }, this.tickerInterval);
    setInterval(() => {
      this.ticker2Index += 1;
      
      this.ticker2Index %= this.ticker2Lines.length;
    }, this.ticker2Interval);
    setInterval(() => {
      this.ticker3Index += 1;
      
      this.ticker3Index %= this.ticker3Lines.length;
    }, this.ticker3Interval);
  },
}
</script>
